/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const RobotoCondensed_300Light = require('./RobotoCondensed_300Light.ttf');
export const RobotoCondensed_300Light_Italic = require('./RobotoCondensed_300Light_Italic.ttf');
export const RobotoCondensed_400Regular = require('./RobotoCondensed_400Regular.ttf');
export const RobotoCondensed_400Regular_Italic = require('./RobotoCondensed_400Regular_Italic.ttf');
export const RobotoCondensed_700Bold = require('./RobotoCondensed_700Bold.ttf');
export const RobotoCondensed_700Bold_Italic = require('./RobotoCondensed_700Bold_Italic.ttf');
